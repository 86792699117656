<template>
  <div>
    <div><Header /></div>
    <div class="main"><router-view /></div>
  </div>
</template>

<script>
export default {
  components: {
    Header: () => import("./components/header"),
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.main {
  /* box-sizing: border-box;
  padding: 1rem; */
  background: #f5f6f7;
  min-height: calc(100vh - 3.75rem);
  padding-bottom: 1rem;
}
</style>