module.exports = { //例子 this.$i18n.t('main.submit_audit')  {{$t('main.status_name')}}

    main: {
        'text1': 'HiChat积分交易系统',
        'text2': 'Hicoin余额',
        'text3': '申诉理由',
        'text4': '我已完成付款，卖家未及时放币',
        'text5': '我向卖家多转了钱',
        'text6': '我并没有收到转款',
        'text7': '收到付款，但金额不符',
        'text8': '其他',
        'text9': '确认申诉',
        'text10': '操作成功',
        'text11': '订单金额',
        'text12': '数量',
        'text13': '单价',
        'text14': '订单号',
        'text15': '打款钱包地址',
        'text16': '地区',
        'text17': '银行名',
        'text18': '账号名',
        'text19': '账户号码',
        'text20': 'SWIFT Code',
        'text21': '凭证',
        'text22': '取消交易',
        'text23': '上传凭证',
        'text24': '申诉',
        'text25': '取消申诉',
        'text26': '完成交易',
        'text27': '确认取消交易？',
        'text28': '确认取消申诉？',
        'text29': '确认收到款项完成交易？',
        'text30': '上传图片大小不能超过 5MB!',
        'text31': '文件上传有误，刷新稍后重试',
        'text32': '订单确认中',
        'text33': '待上传凭证',
        'text34': '处理中',
        'text35': '用户申诉',
        'text36': '代理申诉',
        'text37': '用户取消',
        'text38': '代理取消',
        'text39': '后台取消',
        'text40': '用户完成',
        'text41': '代理完成',
        'text42': '后台完成',
        'text43': '复制成功',
        'text44': '你需要支付',
        'text45': '确认',
        'text46': '取消',
        'text47': '购买',
        'text48': 'Hicoin购买',
        'text49': '购买成功',
        'text50': '立即购买',
        'text51': '法币',
        'text52': '出售',
        'text53': '待处理信息',
        'text54': '笔订单处于',
        'text55': '订单待上传凭证中',
        'text56': '待处理中',
        'text57': '用户申诉中',
        'text58': '代理申诉中',
        'text59': '暂无数据',
        'text60': '订单信息',
        'text61': '订单',
        'text62': '全部',
        'text63': '已取消',
        'text64': '已完成',
        'text65': '申诉中',
        'text66': '进行中',
        'text67': '已完成',
        'text68': '订单列表',
        'text69': '获得金额',
        'text70': '添加收款账号',
        'text71': '立即出售',
        'text72': '你会获得',
        'text73': '添加收款钱包',
        'text74': '钱包地址',
        'text75': '请输入',
        'text76': '银行名',
        'text77': '保存',
        'text78': '编辑收款账号',
        'text79': '添加收款账号',
        'text80': '编辑钱包地址',
        'text81': '添加钱包地址',
        'text82': '账户',
        'text83': '选择收款账号',
        'text84': '选择钱包地址',
        'text85': '收款账号',
        'text86': '个人设置',
        'text87': 'Enter Amount',
        'text88': 'Hicoin出售',
        'text89': '姓',
        'text90': '名',
        'text91': '选择支付币种',
        'text92': '选择代理地区',
        'text93': '支付金额',
        'text94': '汇率',
        'text95': '服务费',
        'text96': '请选择地区',
        'text97': '',
        'text98': '',
        'text99': '',
        'text100': '',
        'text101': '',
        'text102': '',
        'text103': '',
        'text104': '',
        'text105': '',
        'text106': '',
        'text107': '',
        'text108': '',
        'text109': '',
        'text110': '',

    }

}