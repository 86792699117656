import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementLocale from 'element-ui/lib/locale';


import cnLocale from './assets/language/ch';
import enLocale from './assets/language/en';


import elEnLocale from 'element-ui/lib/locale/lang/en';
import elCnLocale from 'element-ui/lib/locale/lang/zh-CN';

import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';

Vue.use(VueI18n);

import {
  Locale
} from 'vant';

let currentLang = sessionStorage.getItem('hichat_app_language');
switch (currentLang) {
  case 'ch':
    Locale.use('zh-CN', zhCN);
    break;
  case 'en':
    Locale.use('en-US', enUS);
    break;

  default:
    Locale.use('en-US', enUS);
    sessionStorage.setItem('language', 'en');
}

const i18n = new VueI18n({
  locale: currentLang,
  silentFallbackWarn: true,
  messages: {
    ch: {
      ...cnLocale,
      ...elCnLocale,
      ...zhCN,
    },
    en: {
      ...enLocale,
      ...elEnLocale,
      ...enUS,
    },

  }
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n