import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        api_loading: false,
        hichat_title: sessionStorage.getItem('hichat_title'),
    },
})