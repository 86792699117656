<template>
  <div
    id="app"
    v-loading="$store.state.api_loading"
    element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
    // ?language=ch&userid=632310e606e986c79e817060&token=04634b9ee29d9f90d9caa23063b79a1b
    if (window.location.search) {
      const url = new URL(window.location.href);
      let language = url.searchParams.get("language") || "";
      let userid = url.searchParams.get("userid") || "";
      let token = url.searchParams.get("token") || "";
      if (language && userid && token) {
        let lang = "en";
        if (language == "ch") {
          lang = "ch";
        }
        sessionStorage.setItem("hichat_app_language", lang);
        sessionStorage.setItem("hichat_app_language", language);
        sessionStorage.setItem("hichat_app_userid", userid);
        sessionStorage.setItem("hichat_app_token", token);
      }
    }
  },
  mounted(){
    
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.btn_no {
  opacity: 0.6;
  background: #2982d8 !important;
}
.btn_ok {
  background: #2982d8 !important;
}
#app {
  max-width: 760px;
  margin: 0 auto;
}
input,
input:focus,
textarea,
textarea:focus,
select,
select:focus {
  font-size: 16px !important;
}
.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}
</style>
