import { Message } from "element-ui"

import pcormove from './pcormove'
import { Toast } from 'vant';

const msg = (type, msg) => {
    if (pcormove._isMobile() == 'app') {
        if (type == 'success') {
            Toast(msg);
        } else {
            Toast(msg);
        }
    } else {
        Message.closeAll();
        if(type == 'success'){
            Message({
                message: msg,
                type: 'success'
            })
        }else if(type == 'error'){
            Message({
                message: msg,
                type: 'error'
            })
        }else{
            Message({
                message: msg,
                type: 'info'
            })
        }
        
    }
}

export default {
    msg
}