import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import Vant from 'vant'
import 'vant/lib/index.css';
import msg from "./assets/tool/msg"; //消息提示框
import {
  num_format
} from "./assets/tool/index";

import i18n from "./i18n.js"

Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(Vuex);
Vue.config.productionTip = false
Vue.prototype.bus = new Vue();
Vue.prototype.msg = msg.msg;
Vue.prototype.num_format = num_format;


/*实时获取 sessionStorage 的值*/
Vue.prototype.resetSetItem = function (key, newVal) {
  //注意hichat_title为要监听的属性，实际开发中请自行修改
  if (key === 'hichat_title') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')