// 使用时间转换插件 moment.js
import moment from 'moment'
let lang = sessionStorage.getItem('language') || "ch";
// 全局  overall
export function time_format(v) {
  if (lang == 'en') {
    // return moment(v).format('DD/MM/YYYY, hh:mma');
    return moment(v).format('DD/MM/YYYY');
  } else {
    // return moment(v).format('YYYY/MM/DD HH:mm');
    return moment(v).format('YYYY/MM/DD');
  }
}

// 数字 每三位数加一个逗号 1,000 
export function num_format(val) {
  if (val == '-') {
    return "-";
  }
  if (val === "0" || val === 0) {
    return "0";
  }
  if (!val) {
    return "";
  }
  let num = val + "";
  if (num.indexOf('.') != -1) {
    let num1 = num.split('.')[0]; //整数
    let num2 = num.split('.')[1]; //小数
    let num3 = num1.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
    if (num2.length == 1) {
      return num3 + "." + num2 + "0";
    } else {
      return num3 + "." + num2;
    }

  } else {
    return num.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,') + ".00";
  }
}