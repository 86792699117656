import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '../layout/layout.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/', //首页
    component: layout,
    children: [{
      path: '/',
      component: () =>
        import('../views/home/home.vue'),
    }]
  }, {
    path: '/', //购买
    component: layout,
    children: [{
      path: 'buy',
      redirect: '/buy/buy_fiat',
      component: () =>
        import('../views/buy/buy.vue'),
      children: [{
          path: 'buy_fiat',
          component: () =>
            import('../views/buy/children/buy_fiat.vue'),
        },
        {
          path: 'buy_jmhb',
          component: () =>
            import('../views/buy/children/buy_jmhb.vue'),
        }
      ]
    }]
  },
  {
    path: '/',
    component: layout,
    children: [{
      path: '/buy_fiat_info',
      component: () =>
        import('../views/buy/children/buy_fiat_info.vue'),
    }]
  },
  {
    path: '/',
    component: layout,
    children: [{
      path: '/buy_jmhb_input',
      component: () =>
        import('../views/buy/children/buy_jmhb_input.vue'),
    }]
  }, {
    path: '/',
    component: layout,
    children: [{
      path: '/buy_appeal',
      component: () =>
        import('../views/buy/children/buy_appeal.vue'),
    }]
  }, {
    path: '/',
    component: layout,
    children: [{
      path: 'order_list',
      redirect: '/order_list/order_underway',
      component: () =>
        import('../views/order_list/order_list.vue'),
      children: [{
          path: 'order_underway',
          component: () =>
            import('../views/order_list/children/order_underway.vue'),
        },
        {
          path: 'order_done',
          component: () =>
            import('../views/order_list/children/order_done.vue'),
        }
      ]
    }]
  },
  {
    path: '/', //出售
    component: layout,
    children: [{
      path: 'sell',
      redirect: '/sell/sell_fiat',
      component: () =>
        import('../views/sell/sell.vue'),
      children: [{
          path: 'sell_fiat',
          component: () =>
            import('../views/sell/children/sell_fiat.vue'),
        },
        {
          path: 'sell_jmhb',
          component: () =>
            import('../views/sell/children/sell_jmhb.vue'),
        }
      ]
    }]
  },
  {
    path: '/',
    component: layout,
    children: [{
      path: '/sell_jmhb_input',
      component: () =>
        import('../views/sell/children/sell_jmhb_input.vue'),
    }]
  }, {
    path: '/', //设置
    component: layout,
    children: [{
      path: 'set',
      redirect: '/set/set_account',
      component: () =>
        import('../views/set/set.vue'),
      children: [{
          path: 'set_account',
          component: () =>
            import('../views/set/children/set_account.vue'),
        },
        {
          path: 'set_addr',
          component: () =>
            import('../views/set/children/set_addr.vue'),
        }
      ]
    }]
  }, {
    path: '/',
    component: layout,
    children: [{
      path: '/add_account',
      component: () =>
        import('../views/set/children/add_account.vue'),
    }]
  }, {
    path: '/',
    component: layout,
    children: [{
      path: '/add_addr',
      component: () =>
        import('../views/set/children/add_addr.vue'),
    }]
  },


  {
    path: '*',
    name: '404',
    component: () =>
      import('../views/404.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router;